import React, { Component } from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import logo from './logo.png';
import device from './sonoist_hm_1.png';
import kidney from './kidney_colour_outline.png';
import heart from './heart_colour_outline.png';
import baby from './baby_colour_outline.png';
import clipboard from './clipboard_coloured.png';
import hospital from './hospital_coloured.png';
import liver from './liver_colour_outline.png';
import lungs from './lungs_colour_outline.png';
import stomach from './stomach_colour_1.png';
import kacper from './sonoist_profile_kn.png';
import laura from './sonoist_profile_laura.png';
import mcgill from './mcgill.png';
import mcgill_2 from './mcgill_2.png';
import vid from './vid.mp4';
import vid_2 from './vid_2.mp4';
import './App.css';

let calendarStringToUse = `<div class='calendar-item'>
  <h3><span>
    Feb 9, 2020
  </span></h3>

  <h5>Application</h5>
  <h5>Due</h5>
</div> <div class='btn-holder'><a class='button' href='http://bit.ly/SonoistApp' target='_blank'>Apply now</a></div>`

class CalendarCarousel extends React.Component {

}

class Carousel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: this.props.items,
      currentItemView: this.props.items[0],
      currentItem: 0,
      calendarString: calendarStringToUse,
      delay: (Math.random() * 800)
    }

    this.carouselChange = this.carouselChange.bind(this);
    this.changeCarouselToPoint = this.changeCarouselToPoint.bind(this);
  }

  componentDidMount() {
    setInterval(() => {
      this.carouselChange();
    }, (5000 + this.state.delay));
  }

  carouselChange() {
    let itemToShow = this.state.currentItem + 1 > this.props.items.length ? 0 : this.state.currentItem + 1;
    let newItem = this.props.items[itemToShow];

    if (newItem === undefined) {
      newItem = this.props.items[0];
      itemToShow = 0;
    }

    this.setState({
      currentItem: itemToShow,
      currentItemView: newItem
    })
  }

  changeCarouselToPoint(number) {
    let newItem = this.props.items[number];

    if (newItem === undefined) {
      newItem = this.props.items[0];
      number = 0;
    }
    this.setState({
      currentItem: number,
      currentItemView: newItem
    });
  }

  render() {
    let classNames = "section carousel split";
    if (this.props.inverted) {
      classNames = `${classNames} reversed-split`;
    }

    return (
      <div id={this.props.id} className={classNames}>
        <div className="left">
          <div className="content">
            <div className="top">
              <div className="dots">
                {this.props.items.map((item, index) =>
                    <div onClick={() => this.changeCarouselToPoint(index)} className={this.state.currentItem === index ? 'selected dot' : 'not-selected dot'} key={this.props.type+'-'+index}></div>
                  )}
              </div>

              <h3 className="barred-title">{this.state.currentItemView.title}</h3>
            </div>

            <div className="bottom">
              {ReactHtmlParser(this.state.currentItemView.text)}
            </div>
          </div>
        </div>
        <div className="right">
          <img src={this.state.currentItemView.image} />
        </div>
      </div>
    )
  }
}

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dates: []
    }

    this.getDates = this.getDates.bind(this);
  }

  componentDidMount() {
    runCanvas();

    // this.getDates();
  }

  getDates() {
    const state = this;
    (async () => {
      var myHeaders = new Headers({
        "Content-Type": "application/json"
      });

      var myInit = {
        method: 'GET',
        headers: myHeaders
      };
      const url = `${process.env.REACT_APP_API_HOST}/api/sessions`;
      var myRequest = new Request(url, myInit);

      const rawResponse = await fetch(myRequest)
      const content = await rawResponse.json();
      let calendarString = content.reduce(function(acc, value) {
        let date = new Date(value.Date);
        let link = "http://bit.ly/SonoistApp" || value.SignupLink;
        return acc + `<div class='calendar-item'>
          <h3><span>
            ${date.toLocaleString('en-us', { month: 'long' })} ${date.getDate()}, ${date.getFullYear()}
          </span></h3>

          <h5>${value.Title}</h5>
          <h5>${value.Time} - ${value.Room}</h5>
        </div>`
      }, "");

      calendarString = calendarString + "<a class='button' href='http://bit.ly/SonoistApp' target='_blank'>Apply Now</a>"

      state.setState({
        dates: content,
        calendarString: calendarString
      });

      return content;
    })();
  }

  render() {
    let carousel_scan_items = [
      {
        title: "Learn how to identify pathology",
        text: "<div>Find <span>positive scans</span> to improve your ultrasound form and to visualize pathognomic findings.</div>",
        image: device
      },
      {
        title: "Become an U/S champ at your hospital",
        text: "<div><span>Ultrasound is used daily</span> and to master the skill requires numerous scans to see what abnormal results look like.</div>",
        image: hospital
      },
      {
        title: "Work with U/S positive patients",
        text: "<div>Our patients <span>help you learn the skill</span> of a particular scan while also helping identify pathognomonic findings.</div>",
        image: stomach
      }
    ];

    let calendarString = this.state.calendarString;

    let carousel_schedule_items = [
      {
        title: "Apply now",
        text: calendarStringToUse,
        image: clipboard
      },
      {
        title: "Never miss a session or scan",
        text: "<div>Our teaching happens often, with <span>patients recruited weekly</span>, each with a positive ultrasound finding.</div> <div class='btn-holder'><a class='button' href='http://bit.ly/SonoistApp' target='_blank'>Apply Now</a></div>",
        image: clipboard
      }
    ];

    return (
      <div className="App">
        <header className="header">
          <a href="#home" className="logo">  <img src={logo} /></a>
          <input className="menu-btn" type="checkbox" id="menu-btn" />
          <label className="menu-icon" htmlFor="menu-btn"><span className="navicon"></span></label>
          <ul className="menu">
            <li><a href="#about">about</a></li>
            <li><a href="#scans">scans</a></li>
            <li><a href="#schedule">schedule</a></li>
            <li><a href="#partners">partners</a></li>
            <li><a href="#subscribe">subscribe</a></li>
          </ul>
        </header>

        <div id="home" className="App-header">
          <div className="fullscreen-bg">
            <video loop muted autoPlay className="fullscreen-bg__video">
              <source src={vid} type="video/mp4" />
            </video>
            <video loop muted autoPlay className="hidden fullscreen-bg__video">
              <source src={vid_2} type="video/mp4" />
            </video>
          </div>

          <div className="text">
            <canvas id="particles"></canvas>
            <div className="main-cta">
              <h1>Become a sonoist.</h1>
              <h1>Scan positive sonographs.</h1>
            </div>
          </div>
        </div>

        <Carousel
          type="scan"
          id="about"
          items={carousel_scan_items} />

        <div id="scans" className="section our-scans">
          <h3 className="barred-title"><a href="https://www.youtube.com/channel/UCgzukqzoYTTEUbxA1JvMnVQ" target="_blank">Our scans</a></h3>

          <div className="scans flex">
            <a href="https://www.youtube.com/watch?v=N750NAEmEso&t=2s" target="_blank">
              <div className="scan">
                <img src={kidney} />
                <h4>Hydronephrosis <br /> Volume status</h4>
              </div>
            </a>

            <a href="https://www.youtube.com/watch?v=Pa3z9zWNfB8 " target="_blank">
              <div className="scan">
                <img src={baby} />
                <h4>1st trimester <br /> Bladder distension</h4>
              </div>
            </a>

            <a href="https://www.youtube.com/watch?v=ew6uJvZDhmw" target="_blank">
              <div className="scan">
                <img src={heart} />
                <h4>Pericardial effusion <br /> Asystole</h4>
              </div>
            </a>

            <a href="https://www.youtube.com/watch?v=Xxdedx1HtHo" target="_blank">
              <div className="scan">
                <img src={lungs} />
                <h4>Pneumothorax <br /> Pleural effusion Hemithorax</h4>
              </div>
            </a>

            <a href="https://www.youtube.com/watch?v=EVQTI7ivhFM" target="_blank">
              <div className="scan">
                <img src={liver} />
                <h4>eFAST views <br /> Gall bladder</h4>
              </div>
            </a>
          </div>
        </div>

        <Carousel
          type="schedule"
          id="schedule"
          inverted={true}
          items={carousel_schedule_items} />

        <div id="partners" className="section info split">
          <div className="left">
            <h3 className="barred-title">Our partners</h3>

            <div className="items flex">
              <div className="item">
                <img src={mcgill} />

                <h4>McGill Medical Students’ Society</h4>
              </div>

              <div className="item">
                <img src={mcgill_2} />

                <h4>Special Project and <br />Community Involvement</h4>
              </div>
            </div>
          </div>

          <div className="right">
            <h3 className="barred-title">The founders</h3>

            <div className="items flex">
              <div className="item">
                <img src={kacper} />

                <h4>Kacper Niburski <br /> McGill MDCM 2021</h4>
              </div>

              <div className="item">
                <img src={laura} />

                <h4>Laura Ran Yan <br /> McGill MDCM 2021</h4>
              </div>
            </div>
          </div>
        </div>

        <div id="subscribe" className="section clear">
          Interested in <span>hosting a session</span>? Email us.

          <div>
            <a href="http://bit.ly/SonoistApp" target="_blank">
              <button>
                Subscribe
              </button>
            </a>
          </div>
        </div>

        <div className="section footer">
          <div className="logo">
            <img src={logo} />
          </div>

          <div className="other">
            <div className="left">
              <div className="column">
                <h4>Sonoist</h4>
                <h5><a href="http://bit.ly/SonoistApp" target="_blank">Subscribe</a></h5>
                <h5><a href="#about">About</a></h5>
              </div>

              <div className="column second">
                <h4><a href="#scans">Scans</a></h4>
                <h5><a href="https://www.youtube.com/watch?v=Xxdedx1HtHo" target="_blank">PTX</a></h5>
              </div>
              <div className="column third">
                <h4 className="hidden-text">adad</h4>
                <h5><a href="https://www.youtube.com/watch?v=ew6uJvZDhmw" target="_blank">Cardiac Views</a></h5>
                <h5><a href="https://www.youtube.com/watch?v=N750NAEmEso&t=2s" target="_blank">Hydronephrosis</a></h5>
              </div>
              <div className="column fourth">
                <h4 className="hidden-text">adad</h4>
                <h5><a href="https://www.youtube.com/watch?v=EVQTI7ivhFM" target="_blank">eFAST</a></h5>
                <h5><a href="https://www.youtube.com/watch?v=Pa3z9zWNfB8" target="_blank">Pelvis + 1st Trimester</a></h5>
              </div>
            </div>

            <div className="right">
              Join <a href="http://bit.ly/SonoistApp" target="_blank"><span>our sessions</span></a> weekly
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function runCanvas() {
  let canvas = document.getElementById("particles");
  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight / 2;
  let context = canvas.getContext("2d");

  let particleCount = 50;
  let particleSizeMin = 2;
  let particleSizeMax = 9;
  let particleSpeedMin = 1;
  let particleSpeedMax= 2;
  let particleLineDistance= 150;
  let particles = [];
  let dxPlus
  let dyPlus
  let distance;

  for(let i=0;i<particleCount;i++){
    if(Math.random()>0.5){
      dxPlus = 0.3;
    }else{
      dxPlus = -0.3;
    }
    if(Math.random()>0.5){
      dyPlus = 0.3;
    } else{
      dyPlus = -0.3;
    }
    particles[i] = {
      x:  Math.floor(Math.random() * (canvas.width + 1)),
      y:  Math.floor(Math.random() * (canvas.height + 1)),
      dx: Math.floor( Math.random() * (particleSpeedMax - particleSpeedMin + 1) ) + particleSpeedMin,
      dy:  Math.floor( Math.random() * (particleSpeedMax - particleSpeedMin + 1) ) + particleSpeedMin,
      size: Math.floor( Math.random() * (particleSizeMax - particleSizeMin + 1) ) + particleSizeMin,
      dxPlus: dxPlus,
      dyPlus: dyPlus
    }
  }

  function drawFrame(){
    context.clearRect(0, 0, canvas.width, canvas.height)

    for(let i=0;i<(particleCount-1);i++){
      for(let j=i+1;j<particleCount;j++){
        distance = Math.sqrt(Math.pow((particles[i].x-particles[j].x),2)+Math.pow((particles[i].y-particles[j].y),2));
        if(distance<particleLineDistance){
          context.beginPath();
          context.moveTo(particles[i].x, particles[i].y);
          context.lineTo(particles[j].x, particles[j].y);
          context.lineWidth = 4;
          context.strokeStyle = "rgba(9, 200, 200, "+(1-distance/particleLineDistance)+")";
          context.stroke();
        }
      }
    }

    for(let i=0;i<particleCount;i++){
      context.beginPath();
      context.arc(particles[i].x,particles[i].y,particles[i].size,0,2*Math.PI);
      context.fillStyle = "#09c8c8";
      context.fill();
      particles[i].x = particles[i].x+particles[i].dxPlus*particles[i].dx;
      particles[i].y = particles[i].y+particles[i].dyPlus*particles[i].dy;
      if(particles[i].x>canvas.width){
        particles[i].x=0;
      }
      if(particles[i].y>canvas.height){
        particles[i].y=0;
      }
      if(particles[i].x<0){
        particles[i].x=canvas.width;
      }
      if(particles[i].y<0){
        particles[i].y=canvas.height;
      }
    }

    setTimeout(drawFrame,20);
  }

  drawFrame();
}

window.onresize = function(event) {
  // runCanvas()
};

export default App;
